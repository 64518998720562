<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `${discountAd ? 'Edit' : "Create" } Discount Ads` }}</h1>
        </div>
        <discount-ad-form  :discount-ad="discountAd" v-loading="loaders.fetching" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import DiscountAdForm from "@/views/Components/SuperAdmin/DiscountAds/Form";

export default {
    components: {
        BackButton,
        DiscountAdForm
    },
    data() {
        return {
            loaders: {
                fetching: false
            },
            discountAd: null,
        };
    },
    mounted() {
        this.getDiscountAd();
    },
    methods: {
        async getDiscountAd() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('AdsModule/_getDiscountAd');
                let {
                    data: { data },
                } = response;
                if(data){
                    this.discountAd = data;
                }
            } catch (error) {

            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  