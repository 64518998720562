<template>
    <div class="mt-3 mb-4 cardStyle p-4 discount-ad-form">
        <div class="row content mt-2">
            <div class="col-12 col-md-9">
                <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                    <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader.submit" class="custom-form-admin">
                        <div class="d-flex justify-content-start align-items-center status">
                            <p class="status-text">Active/Inactive</p>
                            <base-switch class="status-toggle super-admin" onText="" offText="" ref="templateSwitcher"
                                v-model="form.is_active">
                            </base-switch>
                        </div>
                        <base-input name="text" label="Text" v-model="form.text" :rules="{ custom_required: true }"
                            placeholder="Text" maxlength="20" label-required>
                        </base-input>
                        <base-select name="how_often" type="number" label="How Often" v-model="form.how_often" :options="how_often_options" :rules="{ custom_required: true }"
                            placeholder="How Often" label-required>
                        </base-select>
                        <base-input name="url" label="URL" v-model="form.url" :rules="{ custom_required: true,custom_url:true }"
                            placeholder="URL" label-required>
                        </base-input>
                        <base-select name="patient_type" label="Patient Type" v-model="form.patient_type" :options="patientTypes"
                            :rules="{ custom_required: true }" placeholder="Patient type" label-required collapse-tags multiple custom-multiple />
                        <div>
                            <base-button native-type="submit" type="bari" size="xl" :disabled="loader.submit">{{
                                `${!discountAd ? 'Submit' : 'Update'}` }}</base-button>
                        </div>
                    </form>
                </validation-observer>
            </div>
            <div class="col-12 col-md-3 app-preview">
                <div class="sub-content">
                    <div class="data h-100">
                        <p class="sub-heading mt-2">App Previews</p>
                        <preview :text="form.text" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Preview from "@/views/Components/SuperAdmin/DiscountAds/Preview";
import patientType from "@/mixins/patientTypeMixin";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: ['discountAd'],
    components: {
        Preview
    },
    mixins:[patientType,generateErrorMessageMixin],
    data() {
        let how_often_max = 20;
        let how_often_options = [];
        for (let index = 1; index <= how_often_max; index++) {
            how_often_options.push({
                value: index,
                label: index
            });
        }
        return {
            loader:{
                submit: false
            },
            form: {
                is_active: false,
                text:'',
                how_often:'',
                url:'',
                patient_type:''
            },
            how_often_options
        };
    },
    mounted(){
        if(this.discountAd){
            this.form.is_active = this.discountAd.is_active;
            this.form.text = this.discountAd.text;
            this.form.how_often = this.discountAd.how_often;
            this.form.url = this.discountAd.link;
            this.form.patient_type = this.discountAd?.patient_type?.split(',') || [];
        }
    },
    watch: {
        'discountAd':function(newValue){
            this.form.is_active = this.discountAd.is_active;
            this.form.text = this.discountAd.text;
            this.form.how_often = this.discountAd.how_often;
            this.form.url = this.discountAd.link;
            this.form.patient_type = newValue?.patient_type?.split(',') || [];
        }
    },
    methods: {
        async onSubmit() {
            let validation = this.$refs.formValidator;
            this.loader.submit = true;
            try {
                let response = await this.$store.dispatch('AdsModule/_storeOrUpdateDiscountAd',{fd:this.form});

                let { data: { data, message } } = response;
         
                this.$notify.success({
                    title: 'Discount Ad',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$router.push({name:'admin.discount_ads.show'})
            } catch (error) {
                this.showErrorOnForm(error,'Discount Ad',validation)
            }
            this.loader.submit = false;
        }
    },
};
</script>
  
<style scoped lang="scss">
.app-preview{
    margin-top: calc(27.2px + 22px);
}
</style>
  